import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const {
    img1,
    img2,
    img1LinkAdress,
    img2LinkAdress,
    paragraphOne,
    paragraphTwo,
    paragraphThree,
  } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Row className="about-wrapper">
          <Col md={8} sm={12}>
            <Title title="Wybrani partnerzy biznesowi :" />
            <div className="about-wrapper__image">
              <Fade bottom duration={1000} delay={600} distance="30px">
                <a href={img1LinkAdress}>
                  <AboutImg alt="profile picture" filename={img1} />
                </a>
              </Fade>
              <Fade bottom duration={2000} delay={500} distance="30px">
                <a href={img2LinkAdress}>
                  {' '}
                  <AboutImg alt="profile picture" filename={img2} />{' '}
                </a>
              </Fade>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">{paragraphOne || ''}</p>
                <p className="about-wrapper__info-text">{paragraphTwo || ''}</p>
                <p className="about-wrapper__info-text">{paragraphThree || ''}</p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
