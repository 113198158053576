import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import Typing from 'react-typing-animation';

import AvatarImg from '../Image/AvatarImg';
import LogoImg from '../Image/Logoimg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, subtitle, img, mail, phone, imgLogo } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Row className="about-wrapper">
          <Col>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <LogoImg alt="logo picture" filename={imgLogo} />
              </div>
            </Fade>
          </Col>

          <Col className="hero-cta">
            <Row className="hero-ctc-t">
              <Col sm={5}>Telefon</Col>
              <Col sm={7}>
                <a href={phone ? `tel:${phone}` : ''}>{phone}</a>
              </Col>
            </Row>

            <Row>
              <Col sm={5}>E-mail</Col>
              <Col sm={7}>
                <a href={mail ? `mailto:${mail}` : ''}>{mail}</a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="hero-title-wrap">
          <Col sm={7}>
            <Fade left={isDesktop} bottom={isMobile} duration={9000} distance="30px">
              <Typing speed={4}>
                <h1 className="hero-title">{title || ''}</h1>

                <h4>{subtitle || ''}</h4>
              </Typing>
            </Fade>
          </Col>

          <Col sm={5}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="hero-title__image">
                <AvatarImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
        </Row>

        {/* Aboutme Site for a later */}
        {/* <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade> */}
      </Container>
    </section>
  );
};

export default Header;
